import React from 'react';
import { Link } from "react-router-dom";
import BackgroundAni from '../components/BackgroundAni.js'
  
const Home = () => {
  return (
    <div>
      <div className="name_block">
        <h1>Stephen Busic.</h1>
        <h4>web dev & creative</h4>
      </div>

      <div className="bio_block">
        <div className="desc_block">
          <p>yo :) I love making beautiful things on the web.
          Let's work together and make something great.</p>
          <h3>stephenbusicdev@ gmail.com</h3>
        </div>
        <Link to="/resume">
          <button className="resume_button">view resume</button>
        </Link>
      </div>

      <BackgroundAni />
    </div>
  );
};
  
export default Home;