import React from 'react'
import Matter from 'matter-js'


export default class BackgroundAni extends React.Component {

  componentDidMount() {
    let Engine = Matter.Engine,
    Render = Matter.Render,
    World = Matter.World

    let engine = Engine.create({});
    engine.world.gravity.y = 0;

    //create renderer on screen
    let render = Render.create({
      canvas: document.querySelector('.canvas-physics'),
      engine: engine,
      options: {
        width: window.innerWidth,
        height: window.innerHeight,
        wireframes: false,
        background: 'white',
      }
    });

    Matter.Runner.run(engine)
    Render.run(render)

    let all_bodies = []
    let num_bodies = 10

    for (let i = 0; i < 5; i++) {
      all_bodies.push(createNewBody())
    }
    World.add(engine.world, all_bodies)

    setInterval(() => {

      // Remove all shapes that have left the screen
      for (let body of all_bodies) {
        if (isOffScreen(body)) {
          World.remove(engine.world, body)
          removeFromList(body, all_bodies)
        }
      }

      // Spawn new shapes if number of shapes falls below count
      if (all_bodies.length < num_bodies) {
          let new_shape = createNewBody()
          all_bodies.push(new_shape)
          World.add(engine.world, new_shape)
      }
    }, 1000);


  }

  render() {
    return (
      <canvas className="canvas-physics"/>
    )
  }
}

function getRandomArbitrary(min, max) {
  return Math.floor(Math.random() * (max - min) + min)
}

function isOffScreen(body) {
  return body.position.x < -400 || body.position.x > window.innerWidth+200 || body.position.y < -200 || body.position.y > window.innerHeight+200
}

const colors = ['#ff5959', '#ffff59', '#59FF59', '#59acff']

function createNewBody() {
    let new_shape = null
    let x_pos = -300
    let y_pos = getRandomArbitrary(Math.floor(window.innerHeight * 0.2),
                                   Math.floor(window.innerHeight * 0.8))
    let color_num = getRandomArbitrary(0, colors.length)
    let shape_options = { inertia: Infinity,
                          restitution: 1,
                          friction: 0,
                          frictionAir: 0,
                          frictionStatic: 0,
                          render: {
                            fillStyle: colors[color_num],
                            strokeStyle: 'transparent',
                            lineWidth: 3}
                          }

    let shape_num = Math.random()
    if (shape_num < 0.2) { new_shape = Matter.Bodies.circle(x_pos, y_pos, 80, shape_options)
    } else if (shape_num < 0.6) { new_shape = Matter.Bodies.rectangle(x_pos, y_pos, 160, 160, shape_options)
    } else { new_shape = Matter.Bodies.polygon(x_pos, y_pos, 3, 80, shape_options)}

    Matter.Body.setVelocity(new_shape,
                {x: Math.random() * 4,
                 y: Math.random() - 0.5})
    Matter.Body.setAngularVelocity(new_shape, Math.random()/50)

    return new_shape
}

function removeFromList(item, list) {
  const index = list.indexOf(item);
  if (index > -1) { // only splice array when item is found
    list.splice(index, 1); // 2nd parameter means remove one item only
  }
}
