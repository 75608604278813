import React from 'react';

import resumePDF from './stephen_busic_resume.pdf';
  
const Resume = () => {
  return (
    <iframe src={`${resumePDF}#view=fitH`} title="resumePdf" height="100%" width="100%" />
  );
};
  
export default Resume;